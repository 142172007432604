let _resolve, _reject;

const promise = new Promise((resolve, reject) => {
  _resolve = resolve;
  _reject = reject;
});

export { _resolve as resolve };
export { _reject as reject };
export default promise;
