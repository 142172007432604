function getAcceptLanguageValues() {
  // Assume there are less than 10 languages
  let q = 1;

  return navigator.languages
    .slice(0, 10)
    .map(l => `${l};q=0.${10 - q++}`)
}

export default function createAndSetAcceptLanguageVariable(locale) {
  const currentLanguageList = getAcceptLanguageValues()
  currentLanguageList.unshift(locale)
  window.WF_LOCALIZER = Object.freeze({ ACCEPT_LANGUAGE_HEADER: currentLanguageList.join(',') })
}
