import { logError } from './log';

/**
 * @param {string} type either a path or href
 * @param {string} url relative path or full iframe URL
 * @returns {string} unified shell url
 */
export function generateShellUrl(type, url) {
  if (!['path', 'href'].includes(type)) {
    return Promise.reject(
      new Error(`type argument is required either 'path' or 'href'`)
    );
  }
  if (!url) {
    return Promise.reject(
      new Error(
        `url argument is required either relative path or full iframe URL`
      )
    );
  }

  // generates shell url only if accessed within experience cloud's unified shell i.e when we have the module runtime APIs available.
  return System.import('@adobe/exc-app/page')
    .then(({ default: page }) => page.generateShellUrl({ [type]: `${url}` }))
    .catch(error => {
      logError(error);
      return Promise.reject(error);
    });
}
