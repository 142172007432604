import { getIsUnifiedShellEnabled } from './unified-shell-toggles';

export async function getWorkfrontFulfillableDataFromIms() {
  if (!getIsUnifiedShellEnabled()) {
    throw new Error(
      'Need access to ExC runtime to determine current WF product contexts'
    );
  }

  try {
    const instancesProductContext = await getWorkfrontProductContextFromIms();
    const instancesFullfillableData = instancesProductContext.map(ctx =>
      JSON.parse(ctx.fulfillable_data || {})
    );
    return instancesFullfillableData;
  } catch (err) {
    throw new Error(
      `Unable to read WF product context information. Error: ${err}`
    );
  }
}

export async function getImsInfo() {
  if (!getIsUnifiedShellEnabled()) {
    throw new Error(
      'Need access to ExC runtime to determine current WF product contexts'
    );
  }

  const user = await System.import('@adobe/exc-app/user').then(module => module.default)

  return user
    .get('imsInfo')
    .then(imsInfo => {
      return imsInfo;
    })
    .catch(err => {
      throw new Error(`Unable to read IMS context information. Error: ${err}`);
    });
}

export async function getWorkfrontProductContextFromIms() {
  return getImsInfo()
    .then(imsInfo => {
      const instancesProductContext = imsInfo?.imsProfile?.projectedProductContext
        .filter(isWorkfrontProduct)
        .map(projectedProductContext => projectedProductContext.prodCtx);
      return instancesProductContext;
    })
    .catch(err => {
      throw new Error(
        `Unable to read WF product context information. Error: ${err}`
      );
    });
}

function isWorkfrontProduct(projectedProductContext) {
  return projectedProductContext.prodCtx.serviceCode === 'workfront';
}
