let localizationClient;

export function getLocalizationClient() {
  if (localizationClient) {
    return Promise.resolve(localizationClient);
  } else {
    return System.import('@wf-mfe/localize-react').then(({ BrowserClient }) => {
      localizationClient = new BrowserClient({
        namespace: ['unified-shell'],
      });

      return localizationClient;
    });
  }
}

export function getText() {
  return getLocalizationClient().then(client =>
    client.getText.apply(client, arguments)
  );
}
