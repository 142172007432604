export function logError(errorMsg, additionalData = {}) {
  getOrCreateLogger().then(logger => {
    logger.error(errorMsg, additionalData);
  });
}

export function logMessage(errorMsg) {
  getOrCreateLogger().then(logger => {
    logger.debug(errorMsg);
  });
}

let logger;
function getOrCreateLogger() {
  if (logger) {
    return Promise.resolve(logger);
  } else {
    return System.import('@wf-mfe/logger').then(({ createMFELogger }) => {
      logger = createMFELogger({ name: 'unified-shell-bootstrapper' });
      return logger;
    });
  }
}
