/**
 * Checks if running app is running ExC by checking the parent origin and if that fails then falling back to session storage (which checks if the module runtime script is present)
 * @returns boolean
 */
export function getIsInsideExperienceCloud() {
  const parentOrigin = getParentOrigin();
  const isExCParentOrigin = /(https:\/\/)(exc-unifiedcontent\.)?experience(-qa|-stage)?\.adobe\.(com|net)$/.test(
    parentOrigin
  );
  const checkIfPreviouslyLoadedModuleRuntime =
    window.sessionStorage.getItem('unifiedShellMRScript') !== null;
  return isExCParentOrigin || checkIfPreviouslyLoadedModuleRuntime;
}

/**
 * Checks whether the the module runtime for unified shell is loaded (which means WF is loaded within unified shell)
 * @returns boolean
 */
export function getIsUnifiedShellEnabled() {
  return window['exc-module-runtime'] !== undefined;
}

function getParentOrigin() {
  const locationsAreDistinct = window.location !== window.parent.location;
  const parentOrigin = (
    (locationsAreDistinct ? document.referrer : document.location) || ''
  ).toString();

  if (parentOrigin) {
    return new URL(parentOrigin).origin;
  }

  const currentLocation = document.location;

  // this is not supported in Firefox, so the checks above are required
  if (
    currentLocation.ancestorOrigins &&
    currentLocation.ancestorOrigins.length
  ) {
    return currentLocation.ancestorOrigins[0];
  }

  return '';
}
